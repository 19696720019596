import React from 'react'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import NavBar from '../../atoms/NavBar'
import { Image, Flex, Container, Button, VStack, Heading, Text } from '@chakra-ui/react'
import BackButton from '../../atoms/nav-controls/BackButton'
import Card from '../../atoms/Card'
import { updateEvent } from '../../../../api/app/events'

const Archived = ({ id, uuid, inGlacierStorage, isRestoring }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const handleUnArchiveEvent = () => {
		var eventData = { id, status: 'active', contributorsNotified: false }
		dispatch(updateEvent(eventData))
	}

	return (
		<>
			<NavBar>
				<Flex>
					<BackButton onClick={() => navigate(-1)} />
				</Flex>
			</NavBar>
			<Container maxW="container.xl" py="1rem">
				<Card p="1rem" alignItems="center">
					<VStack spacing="1rem">
						<Image src="/assets/images/graphics/new_vidday.svg" alt="VidDay Archived" />
						<Heading as="h2" variant="hero">
							Your VidDay is sleeping
						</Heading>
						<Text>
							<strong>
								Your event page was laid to rest because it&apos;s been a while since we&apos;ve seen
								you.
							</strong>
						</Text>
						<Text>
							<strong>You can however bring it back from it&apos;s sleepy slumber!</strong>
						</Text>
						<Text>
							It may take up to 12 hours to recover all your media, if you&apos;d like to wake it up click
							the button below.
						</Text>
						<br />
						<Flex justify="center">
							<Button onClick={handleUnArchiveEvent}>Wake up my event</Button>
						</Flex>
						<br />
					</VStack>
				</Card>
			</Container>
		</>
	)
}

export default Archived
